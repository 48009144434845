<template>
  <div class="main-wrap">
    <h4 class="title-h4">设置密码</h4>
    <div class="Tip" v-show="step==1">为确认是您本人操作，请使用短信验证码认证</div>
    <div class="input-box">
      <span class="name" v-show="step==1">当前手机号：{{ $store.state.userInfo.mobile }}</span>
      <div class="input-info" v-show="step==1">
        <span class="tip">验证码</span>
        <a-input class="input" v-model="verCode" :maxLength=6 placeholder="请输入验证码" />

        <div class="button">
          <a-button type="link" @click="onDxCode()" block>
            {{ isDxCode ? "获取验证码" : countdown + '秒后重新发送' }}
          </a-button>
        </div>
      </div>
      <div class="input-info" v-show="step==2">
        <span class="tip">新密码</span>
        <a-input-password class="input" v-model="password"  placeholder="请输入新密码" />
      </div>
      <div class="input-info" v-show="step==2">
        <span class="tip">确认密码</span>
        <a-input-password class="input" v-model="ConfirmPassword" placeholder="请输入确认密码" />
      </div>
    </div>

    <div class="btn-foot">
      <p v-show="step==2" class="all-btn-small" @click="onEditPassword()">确认</p>
      <p v-show="step==1" class="all-btn-small" @click="onNext()">下一步</p>
    </div>
  </div>
</template>

<script>
import {encryptDes} from '@/unit/DESCypher.js' // 引用具体的方法
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      step: 1, //步骤
      countdown: 60,
      isDxCode: true, //是否获取验证码
      verCode: '', //验证码
      password: '', //密码
      ConfirmPassword: '', //确认密码
      mobileCheckStr: '', // 校验验证码返回字符取代修改密码时手机号
    }
  },
  // 事件处理器
  methods: {
    // 获取验证码
    onDxCode() {
      this.$ajax({
        url: '/hxclass-pc/user/upPsSend',
        method: "get",
        params: {
          phone: this.$store.state.userInfo.mobile, //手机号
          mobileType: this.$store.state.userInfo.mobileType, //区号
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("短信已发送，请注意查收！");
          // 倒计时
          this.sendemail(this.countdown);
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 倒计时
    sendemail(s) {
      if (s == 0) {
        this.countdown = 60;
        this.isDxCode = true;
        s = 60;
        clearTimeout(this.timeOut);
        return;
      } else {
        this.countdown = s;
        this.isDxCode = false;
        s--;
      }
      this.timeOut = setTimeout(() => {
        this.sendemail(s);
      }, 1000);
    },
    // 下一步
    onNext() {
      if (!this.verCode) {
        this.$message.warning('请输入短信验证码');
        return false;
      } else if (!this.$regular.dxcode.reg.test(this.verCode)) {
        this.$message.warning(this.$regular.dxcode.msg);
        return false;
      }

      // 校验验证码
      this.$ajax({
        url: '/hxclass-pc/user/upPs/check',
        method: "get",
        params: {
          mobileType: this.$store.state.userInfo.mobileType,
          phone: this.$store.state.userInfo.mobile,
          captcha: this.verCode,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.mobileCheckStr = res.data;
          this.step = 2
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 修改密码
    onEditPassword() {
      if (!this.password) {
        this.$message.warning("请输入密码");
        return 
      } else if (!this.$regular.password.reg.test(this.password)) {
        this.$message.warning(this.$regular.password.msg);
        return 
      } else if (!this.ConfirmPassword) {
        this.$message.warning("请输入确认密码");
        return 
      } else if (this.password !== this.ConfirmPassword) {
        this.$message.warning("两次密码输入不一致");
        return 
      }

      const newPassword = encryptDes(this.password)  //新密码
      const confirm = encryptDes(this.ConfirmPassword) // 确认新密码
      const encmobile = encryptDes(this.$store.state.userInfo.mobile) // 手机号加密
      let password = encodeURIComponent(newPassword)
      let ConfirmPassword = encodeURIComponent(confirm)
      // let mobile = encodeURIComponent(encmobile)
      if(!this.mobileCheckStr) {
        return this.$message.warning("数据丢失，请重新校验手机号！");
      }
      this.$ajax({
        url: '/hxclass-pc/user/new/updatePs',
        method: "POST",
        params: {
          // phone: mobile,
          phone: this.mobileCheckStr,
          newPassword: password,
          confirm: ConfirmPassword,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success('修改成功');
          // 用户信息
          this.getUserInfo()
          this.$router.go(-1)
        } else {
          this.$message.error(res.message);
        }
      })
    },

    // 获取用户信息
    getUserInfo() {
      this.$ajax({
        url: "/hxclass-pc/user/info",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          res.data.isLogin = true
          this.$store.commit("updateUserInfo", res.data);
        }
      });
    },
    inputChange(){
      this.tipsIndex = null
    },

    // 弹窗回调事件
    getEvent(){
      /** 需要执行的任务 **/
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 检查是否有密码
    this.isHavePSW = this.$store.state.userInfo.password ? true : false
   },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.input-box {
  width: 472px;
  margin-top: 12px;
  .name {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 27px;
  }
  .input {
    margin-top: 12px;
    height: 44px;
    /deep/.ant-input{
      height: 44px;
    }
  }
  /deep/ .paw {
    -webkit-text-security: disc !important;
  }
  .tips {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ff0000;
    img {
      position: relative;
      top: -2px;
      width: 13px;
      height: 13px;
      margin-right: 4px;
    }
  }
}
.btn-foot {
  margin-top: 40px;
  display: flex;
  .cancel {
    text-align: center;
    line-height: 40px;
    width: 138px;
    border-radius: 40px;
    height: 40px;
    color: @theme;
    margin-right: 54px;
    border: 1px solid @theme;
    cursor: pointer;
  }
}
.Tip {
  font-size: 24px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
}
.input-info {
  margin-top: 32px;
  position: relative;
  .tip {
    font-size: 18px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #333333;
    line-height: 27px;
    display: block;
  }
  .button {
    display: flex;
    align-items: center;
    width: 125px;
    height: 24px;
    position: absolute;
    bottom: 8px;
    right: 0;
    border-left: 1px solid #c4c4c4;
  }
}
@media screen and (max-width: 16000px) {
  .main-wrap {
    .title-h4 {
      font-size: 22px !important;
    }
  }
  .Tip {
    font-size: 20px !important;
  }
}
</style>
